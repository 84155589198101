<template>
  <a-spin :spinning="page_loading">
    <div class="wizard-side-border">
      <sdPageHeader :title=" $t.paymentScreen " />
      <Main>
        <BasicFormWrapper class="basic-form-inner theme-light">
          <div class="atbd-form-checkout">
            <a-row justify="center">
              <a-col :xs="18">
                <sdCards headless>
                  <a-alert
                    v-if="show_page_error"
                    type="error"
                    :message="page_error_content"
                  />
                  <a-row
                    v-else
                    :gutter="25"
                    type="flex"
                    justify="center"
                  >
                    <a-col
                      :xxl="24"
                      :lg="24"
                      :sm="24"
                      :xs="24"
                      :style="{textAlign:'center'}"
                    >
                      <PricingCard style="margin-bottom: 30px">
                        <div v-if="selected.id !== ''">
                          <Badge
                            class="pricing-badge"
                            style="color: rgb(9 9 9 / 82%);font-size: 17px;font-weight: 600;"
                          >
                            {{ $t.selectedPackage }}
                          </Badge>
                          <br><br>
                          <span
                            class="currency"
                            :style="{ color:'black',}"
                          >
                            {{ selected.package_name }} ({{ selected.show }} {{ $t.credit }})
                          </span>
                          <br>
                          <span
                            class="package-user-type"
                            :style="{ color:'gray'}"
                          >
                            {{ $t.amountDue }}: {{ selected.show }} * {{ selected.unit }} {{ selected.currency_code }} = <b>{{ selected.total
                            }} {{ selected.currency_code }}</b>
                          </span>
                        </div>
                        <div v-else>
                          <Badge class="pricing-badge">
                            {{ $t.choosePackage }}
                          </Badge>
                        </div>
                      </PricingCard>
                    </a-col>
                  </a-row>
                  <a-row
                    :gutter="25"
                    type="flex"
                    justify="center"
                  >
                    <a-col
                      v-for="item in packages"
                      :key="item.id"
                      :xxl="6"
                      :lg="8"
                      :sm="8"
                      :xs="24"
                    >
                      <div class="packet-card-item">
                        <div class="badge-wrap">
                          <Badge
                            class="pricing-badge"
                            :class="item.colors"
                            type="dark"
                          >
                            {{ item.package_name }}
                          </Badge>
                        </div>
                        <div class="price-wrap">
                          <span class="package-user-type"><span>{{ item.unit }}<sup>{{ item.currency_code
                          }}</sup></span> {{ $t.forEachCargo }}</span>
                          <sdHeading
                            class="price-amount"
                            as="h3"
                          >
                            <sub class="currency">
                              <span style="font-weight: bold">{{ item.show }}</span>
                              {{ $t.thisIsTheCurrentPriceForRegistration }}
                            </sub>
                          </sdHeading>
                        </div>
                        <div class="btn-wrap">
                          <sdButton
                            class="btn-outlined"
                            :class="item.colors"
                            size="default"
                            type="success"
                            style="margin-top:4px;"
                            @click="setPlan(item)"
                          >
                            {{ $t.applySelection }}
                          </sdButton>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </sdCards>
              </a-col>
            </a-row>
            <a-row
              v-if=" ! show_page_error"
              justify="center"
            >
              <a-col :xs="12">
                <div class="payment-method-form theme-light">
                  <div class="shipping-selection">
                    <div class="shipping-selection__card">
                      <sdCards
                        headless
                        style="margin-bottom: 0"
                      >
                        <a-form layout="vertical">
                          <a-form-item
                            name="number"
                            :label="$t.cardNumber"
                          >
                            <a-input
                              v-model:value="cardInfo.number"
                              placeholder="6547870269872527"
                              maxlength="16"
                              @keypress="isNumber($event)"
                            />
                          </a-form-item>
                          <a-form-item
                            name="name"
                            :label="$t.nameOnCard"
                          >
                            <a-input
                              v-model:value="cardInfo.name"
                              placeholder="Full name"
                              maxlength="50"
                            />
                          </a-form-item>
                          <a-form-item
                            name="month"
                            initial-value=""
                            :label="$t.moon"
                          >
                            <div class="ex-date-year-wrap d-flex">
                              <a-select
                                v-model:value="cardInfo.month"
                                :style="{ width: '100%' }"
                                show-search
                              >
                                <a-select-option
                                  v-for="(item, index) in 12"
                                  :key="index"
                                  :value="item"
                                >
                                  {{ item }}
                                </a-select-option>
                              </a-select>
                            </div>
                          </a-form-item>
                          <a-form-item
                            name="year"
                            :label="$t.year"
                          >
                            <div class="ex-year">
                              <a-select
                                v-model:value="cardInfo.year"
                                :style="{ width: '100%' }"
                                show-search
                              >
                                <a-select-option
                                  v-for="(item, index) in 12"
                                  :key="index"
                                  :value="new Date().getFullYear() + index"
                                  show-search
                                >
                                  {{ parseInt(new Date().getFullYear().toString().slice(-2)) + index }}
                                </a-select-option>
                              </a-select>
                            </div>
                          </a-form-item>
                          <a-form-item
                            name="cvv"
                            label="CVV"
                          >
                            <div class="cvv-wrap">
                              <a-input
                                v-model:value="cardInfo.cvv"
                                :style="{ width: '100%' }"
                                placeholder="XXX"
                                maxlength="3"
                                @keypress="isNumber($event)"
                              />
                            </div>
                          </a-form-item>
                          <a-form-item>
                            <a-button
                              class="btn-next"
                              type="primary"
                              :loading="page_loading"
                              :style="{ width: '100%' }"
                              @click="sendPayment"
                            >
                              <sdFeatherIcons
                                type="check-circle"
                                size="16"
                                :style="{ margin: '3px' }"
                              />
                              {{ $t.payAndContinue }}
                            </a-button>
                          </a-form-item>
                        </a-form>
                      </sdCards>
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </BasicFormWrapper>
      </Main>
    </div>
    <span v-html="sendFormFields" />
  </a-spin>
</template>

<script>
import { Main } from '../styled'
import { PricingCard } from './style'

export default {
  name: 'MainPage',
  components: {
    Main,
    PricingCard,
  },
  data() {
    return {
      page_loading: false,
      show_page_error: false,
      page_error_content: '',
      packages: [],
      sendFormFields: '',
      selected: {
        id: '',
        package_name: '',
        unit: 0,
        total: 0,
        show: 0,
        currency_code: '',
      },
      cardInfo: {
        number: '',
        name: '',
        month: '',
        year: '',
        cvv: '',
        total: 0,

        packid: 0,
      },
    }
  },
  mounted() {
    this.getPackages()
    if (this.$route.query.state !== undefined) {
      if (this.$route.query.state === 'success') {
        this.$message.success(this.$t.theProvisionHasBeenSuccessfullyReceived)
        setTimeout(() => {
          this.$router.push({ name: 'C.Payment.Page' })
        }, 1000)
      } else {
        this.$message.error(this.$route.query.fail_message ?? this.$t.paymentFailed)
      }
    }
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    setPlan(plan) {
      this.selected.id = plan.id
      this.selected.package_name = plan.package_name
      this.selected.unit = plan.unit
      this.selected.total = plan.total
      this.selected.show = plan.show
      this.selected.currency_code = plan.currency_code

      this.cardInfo.packid = plan.id
    },
    checkForm() {
      if (this.cardInfo.packid === 0) {
        this.$message.error(this.$t.youDidNotChooseAPackage)
        return false
      }
      if (this.cardInfo.number.length < 16) {
        this.$message.error(this.$t.cardNumberMustBe16Digits)
        return false
      }
      if (this.cardInfo.name.length < 3) {
        this.$message.error(this.$t.cardholderNameMustBeAtLeast3Characters)
        return false
      }
      if (this.cardInfo.month < 1) {
        this.$message.error(this.$t.theExpirationMonthOfTheCardMustBeSelected)
        return false
      }
      if (this.cardInfo.year < 1) {
        this.$message.error(this.$t.theExpiryYearOfTheCardMustBeSelected)
        return false
      }
      if (this.cardInfo.cvv.length !== 3) {
        this.$message.error(this.$t.theCvvNumberOfTheCardMustBe3Digits)
        return false
      }
      return true
    },
    sendPayment() {
      const formValid = this.checkForm()
      if (formValid === false) {
        return
      }

      this.page_loading = true
      this.$restMethods.postForWebApp('/send-payment', this.cardInfo).then(response => {
        if ( ! response.hasError()) {
          this.sendFormFields = response.getData().data
          setTimeout(() => {
            document.getElementById('sendPaymentForm').submit()
          }, 1000)
        } else {
          this.page_loading = false
        }
      })
    },
    getPackages() {
      this.page_loading = true
      this.$restMethods.postForCargongWebApp('/top-up-balance-open', {}, { show_error: false }).then(response => {

        if ( ! response.hasError()) {

          const data = response.getData()

          this.packages = data.packages

        } else {

          this.show_page_error = true
          this.page_error_content = response.getErrorMessage()

        }

        this.page_loading = false

      })
    },
  },
}
</script>
<style>

.packet-card-item {
  border-radius: 15px;
  padding: 30px 30px;
  -webkit-box-shadow: 0px 0px 44px -5px rgb(0 0 0 / 27%);
  -moz-box-shadow: 0px 0px 44px -5px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 14px -5px rgb(0 0 0 / 27%);
}

.packet-card-item .badge-wrap badge.pricing-badge {
  border-radius: 15px;
  padding: 6px 20px;
  background-color: #20c99733;
  color: #20c997;
  font-weight: 600;
}

.packet-card-item .price-wrap {
  margin-top: 20px;
  margin-bottom: 20px;
  /*margin: 30px 0px;*/
}

.price-amount {
  line-height: 0;
}

.price-amount sub {
  font-size: 12px;
  color: gray;
  font-weight: 300;
}

span.package-user-type {
  font-size: 11px;
  color: gray;
  font-weight: 500;
}

span.package-user-type > span {
  font-size: 40px;
  color: #000000;
  font-weight: bold;
}

span.package-user-type > span > sup {
  font-weight: 500;
  font-size: 13px;
  color: gray;
  margin-right: 2px;
}

.package-properties {
  line-height: 22px;
}

.package-orange {
  color: #ff6b00 !important;
  background-color: #ff6b0033 !important;
}

.btn-wrap > .package-orange {
  color: #ffffff !important;
  background-color: #ff6b00 !important;
}

.package-green {
  color: #20c997 !important;
  background-color: #20c99733 !important;
}

.btn-wrap > .package-green {
  color: #ffffff !important;
  background-color: #20c997 !important;
}

.package-blue {
  color: #8185f8 !important;
  background-color: #d7d8fa !important;
}

.btn-wrap > .package-blue {
  color: #ffffff !important;
  background-color: #8185f8 !important;
}

.package-pink {
  color: #ff80b4 !important;
  background-color: #fae4ed !important;
}

.btn-wrap > .package-pink {
  color: #ffffff !important;
  background-color: #ff80b4 !important;
}

</style>